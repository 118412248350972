import "./App.css";
import messages from "./assets/Local/Local";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Utils/Routes";
import Navbar from "./Components/Admin/Navbar/Navbar";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

function App() {
  const { lang } = useSelector((state) => state.main);
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <IntlProvider locale={lang} defaultLocale={lang} messages={messages[lang]}>
      <div
        className={lang === "ar" ? "rtl" : "ltr"}
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <BrowserRouter>
          <Toaster position="bottom-center" reverseOrder={false} />
          {isAuthenticated && <Navbar />}
          {Routes}
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
}

export default App;
