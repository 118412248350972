import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  excelFileURL: "",
  searchData: [],
  allGrades: [],
};

export const addSalaryGrade = createAsyncThunk(
  "salaryGrade/addSalaryGrade",
  async (data) => {
    return await axiosInstance
      .post("SalaryGrade/AddSalaryGrade", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message);
        return err;
      });
  }
);
// export const getAllSalaryGrade = createAsyncThunk(
//   "salaryGrade/addSalaryGrade",
//   async (data) => {
//     return await axiosInstance
//       .post("SalaryGrade/AddSalaryGrade", data)
//       .then((response) => {
//         toast.success(messages[localStorage.getItem("lang")].shared.done, {});
//         return response;
//       })
//       .catch((err) => {
//         toast.error(err.response.data.error.message, {});

//         return err;
//       });
//   }
// );

export const getGradeExcelFile = createAsyncThunk(
  "salaryGrade/getGradeExcelFile",
  async () => {
    return await axiosInstance
      .get("SalaryGrade/Get_SalaryGradeSampleExcelUrl")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message, {});

        return err;
      });
  }
);
export const uploadGradeExcelFile = createAsyncThunk(
  "salaryGrade/uploadGradeExcelFile",
  async (data) => {
    return await axiosInstance
      .post("SalaryGrade/Upload_ApplicantExcel", data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message, {});

        return err;
      });
  }
);

export const getAllSalaryGrades = createAsyncThunk(
  "salaryGrade/allSalaryGrades",
  async (params) => {
    return await axiosInstance
      .get("SalaryGrade/Get/Pending", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message, {});
        return err;
      });
  }
);

export const SearchSalaryGrades = createAsyncThunk(
  "salaryGrade/searchSalaryGrades",
  async (params) => {
    return await axiosInstance
      .get("SalaryGrade/Get/Approved", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message, {});

        return err;
      });
  }
);

export const updateGradeStatus = createAsyncThunk(
  "salaryGrade/UpdateStatus",
  async (data) => {
    try {
      let res = await axiosInstance.put("SalaryGrade/UpdateStatus", {
        ...data,
      });
      return res;
    } catch (error) {
      toast.error("Error !");
      return error;
    }
  }
);

const SalaryGradeSlice = createSlice({
  name: "salaryGrade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add new salary grade
    builder.addCase(addSalaryGrade.fulfilled, (state, action) => {});

    //
    builder.addCase(getGradeExcelFile.fulfilled, (state, action) => {
      state.excelFileURL = action.payload.data;
    });
    //all grades
    builder.addCase(getAllSalaryGrades.fulfilled, (state, action) => {
      state.allGrades = action.payload.data.data;
    });

    builder.addCase(SearchSalaryGrades.fulfilled, (state, action) => {
      state.searchData = action.payload.data.data;
    });

    builder.addCase(updateGradeStatus.fulfilled, (state, action) => {
      console.log(action.payload);
    });
  },
});

export const SalaryGradeReducer = SalaryGradeSlice.reducer;
