import React from "react";
import { isUserHasPermission } from "../../Utils/Permissions";
import Navbar from "../../Components/Admin/Navbar/Navbar";
import { Outlet } from "react-router-dom";

export default function MainLayout(props) {
  const isAuthenticated = localStorage.getItem("permissionArr") ? true : false;
  const permissionArr =
    props.permission && typeof props.permission !== "boolean"
      ? props.permission.map((elt) => isUserHasPermission(elt))
      : [];

  return <div>{props.children}</div>;
}
