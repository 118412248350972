// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtl {
  text-align: right;
  direction: rtl;
}
/* 
.MuiDialog-paper[dir="rtl"] {
  text-align: right;
}

.MuiMenuItem-root[dir="rtl"] {
   text-align: right;
} */
body {
  padding-top: 0rem;
}
.mui-1jrzfe4-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 600;
  font-size: 1.5rem;
  /* text-align: right; */

}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;;;;;;;GAOG;AACH;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;;AAEzB","sourcesContent":[".rtl {\n  text-align: right;\n  direction: rtl;\n}\n/* \n.MuiDialog-paper[dir=\"rtl\"] {\n  text-align: right;\n}\n\n.MuiMenuItem-root[dir=\"rtl\"] {\n   text-align: right;\n} */\nbody {\n  padding-top: 0rem;\n}\n.mui-1jrzfe4-MuiInputBase-root-MuiOutlinedInput-root {\n  font-weight: 600;\n  font-size: 1.5rem;\n  /* text-align: right; */\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
