import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./../index";

const initialState = {
  userToken: localStorage.getItem("token") || null,
  error: null,
  isLoading: false,
  isAuthenticated: localStorage.getItem("isAuthenticated") || null,
};

export const handleLogin = createAsyncThunk("auth/login", async (data) => {
  return await axiosInstance
    .post("Auth/login", data)
    .then((response) => response)
    .catch((err) => err);
});

export const confirmLoginOtp = createAsyncThunk(
  "auth/confirmLoginOtp",
  async (data) => {
    return await axiosInstance
      .post("Auth/ConfirmLoginOtp", data)
      .then((response) => response)
      .catch((err) => err);
  }
);

export const resendOtpCode = createAsyncThunk(
  "auth/resendOtpCode",
  async (data) => {
    return await axiosInstance
      .post("Auth/ResendLoginOTP", data)
      .then((response) => response)
      .catch((err) => err);
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.userToken = null;
      localStorage.removeItem("token");
      state.isAuthenticated = false;
      localStorage.removeItem("isAuthenticated");
    },
  },
  extraReducers: (builder) => {
    // Login

    builder.addCase(handleLogin.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      if (
        action?.payload?.status !== 200
        // action.payload?.response?.status !== 200
      ) {
        console.log(action.payload);

        state.error = action.payload.response?.data.error.message;
      }
      if (action.payload.status === 200) {
        console.log(action.payload);
        state.error = null;

        state.userToken = action.payload.data.data.token;
      }

      state.isLoading = false;
    });
    builder.addCase(handleLogin.rejected, (state, action) => {
      console.log(action.payload);
    });

    // Confirm OTP
    builder.addCase(confirmLoginOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.error.message;
      console.log(action.payload);
    });
    builder.addCase(confirmLoginOtp.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(confirmLoginOtp.fulfilled, (state, action) => {
      if (action.payload.status !== 200) {
        state.error = "invalid OTP";
        console.log("jhjhkhkjhkj");
      }
      // if (action.payload?.response?.status !== 200) {
      //   state.error = "invalid OTP";
      //   console.log("jhjhkhkjhkj");
      // }
      if (action.payload.status === 200) {
        console.log(action.payload);

        state.isAuthenticated = true;
        localStorage.setItem("token", action.payload?.data.data.token);
        localStorage.setItem("isAuthenticated", true);
      }
      state.isLoading = false;
    });

    //Resend OTP
    builder.addCase(resendOtpCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.error.message;
    });
    builder.addCase(resendOtpCode.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(resendOtpCode.fulfilled, (state, action) => {
      // state.userToken = action.payload.data.data.token;
      // localStorage.setItem("token", action.payload.data.data.token);
      state.error = null;
      if (action.payload.status !== 200) {
        state.error = action.payload.response?.data.error.message;
      }
      state.isLoading = false;
    });
  },
});

export const AuthReducer = AuthSlice.reducer;
export const { logOut } = AuthSlice.actions;
