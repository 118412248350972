import { configureStore } from "@reduxjs/toolkit";
import { MainReducer } from "./slices/MainSlice";
import { AuthReducer } from "./slices/AuthSlice";
import { LookupReducer } from "./slices/LookupsSlice";
import { SalaryGradeReducer } from "./slices/SalaryGradeSlice";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    main: MainReducer,
    lookups: LookupReducer,
    SalaryGrade: SalaryGradeReducer,
  },
});

export default store;
