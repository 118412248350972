import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "./interceptors";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Accept-Language":
      localStorage.getItem("lang") === "en" ? "en-US" : "ar-SA",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
