import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as LazyComponent from "./LazyLoading";
import Loader from "../Components/Loader/Loader";
import ProtectedRoutes from "../Components/ProtectedRoutes/ProtectedRoutes";

const Routess = (
  <>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="login" element={<LazyComponent.Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoutes
              path="/"
              component={LazyComponent.Home}
              permission={true}
            />
          }
        />
        <Route
          path="ai"
          element={
            <ProtectedRoutes
              path="ai"
              component={LazyComponent.NewSalary}
              permission={true}
            />
          }
        />

        <Route
          path="searchSalaryGrade"
          element={
            <ProtectedRoutes
              path="searchSalaryGrade"
              component={LazyComponent.SearchSalaryGrade}
              permission={true}
            />
          }
        />
        <Route
          path="allSalaryGrade"
          element={
            <ProtectedRoutes
              path="allSalaryGrade"
              component={LazyComponent.AllSalaryGrade}
              permission={true}
            />
          }
        />
        <Route path="login" element={<LazyComponent.Login />} />
        <Route path="otp" element={<LazyComponent.Otp />} />

        <Route
          path="forgetPassword"
          element={
            <ProtectedRoutes
              path="forgetPassword"
              component={LazyComponent.Otp}
              permission={true}
            />
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoutes
              path="profile"
              component={LazyComponent.Profile}
              permission={true}
            />
          }
        />
      </Routes>
    </Suspense>
  </>
);

export default Routess;
