import { lazy } from "react";

export const Home = lazy(() => import("./../Pages/Home/Home"));
export const Login = lazy(() => import("./../Pages/auth/Login/Login"));
export const Otp = lazy(() => import("./../Pages/auth/Otp/Otp"));
export const Profile = lazy(() => import("./../Pages/Profile/Profile"));
export const AllSalaryGrade = lazy(() =>
  import("./../Pages/AllSalaryGrades/AllSalaryGrades")
);

export const SearchSalaryGrade = lazy(() =>
  import("./../Pages/SearchSalaryGrade/SearchSalaryGrade")
);
export const NewSalary = lazy(() => import("./../Pages/AddNew/AddNew"));
