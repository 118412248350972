import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { ClickAwayListener, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLang } from "../../../Redux/slices/MainSlice";
import logo from "./../../../assets/Images/image.png";
import messages from "../../../assets/Local/Local";
import { injectIntl } from "react-intl";

function Navbar(props) {
  const dispatch = useDispatch();
  const messages = props.intl.messages;
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.main);
  const { isAuthenticated, userToken } = useSelector((state) => state.auth);
  function switchLang() {
    dispatch(changeLang(lang === "ar" ? "en" : "ar"));
  }
  function logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");
    dispatch(logOut());
    navigate("/login");
  }
  return (
    <Paper>
      <nav className="navbar navbar-expand-lg fixed-top bg-light">
        <div className="container ">
          <Link className="navbar-brand col-2" to="/">
            <img src={logo} alt="logo" className="w-75" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse col-9 "
            id="navbarSupportedContent"
          >
            <ul
              className={` ${
                lang === "en" ? "ms-auto" : "me-auto"
              } navbar-nav  mb-2 mb-lg-0`}
            >
              {isAuthenticated ? (
                <>
                  <li className="nav-item mx-4 mx-2">
                    <NavLink
                      className="nav-link fw-bold "
                      aria-current="page"
                      to="/"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item mx-4">
                    <NavLink className="nav-link fw-bold" to="/ai">
                      New
                    </NavLink>
                  </li>
                  <li className="nav-item mx-4">
                    <NavLink className="nav-link fw-bold" to="/allSalaryGrade">
                      Salary Grades
                    </NavLink>
                  </li>

                  <li className="nav-item mx-4">
                    <NavLink
                      className="nav-link fw-bold"
                      to="/searchSalaryGrade"
                    >
                      Search
                    </NavLink>
                  </li>
                  <li className="nav-item mx-4">
                    <div
                      className="nav-link fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // logOut();
                        // dispatch(logOut());
                      }}
                    >
                      Logout
                    </div>
                  </li>
                  <div
                    className="nav-link fw-bold"
                    onClick={() => {
                      switchLang();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {messages.buttons.langBtn}
                  </div>

                  <NavLink to={"profile"} className="ms-4 icon">
                    EI
                  </NavLink>
                </>
              ) : (
                <>
                  {" "}
                  <li className="nav-item mx-4">
                    <NavLink className="nav-link fw-bold" to="/login">
                      Login
                    </NavLink>
                  </li>
                  <li
                    className="nav-item mx-4"
                    onClick={() => {
                      switchLang();
                    }}
                  >
                    <div className="nav-link fw-bold">{lang}</div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </Paper>
  );
}

export default injectIntl(Navbar);
