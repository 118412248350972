export const PERMISSIONS = {
  SETUP: 1,
};

export const ConvertPermissions = () => {
  if (localStorage.getItem("token") && localStorage.getItem("permissions")) {
    let permissions = localStorage.getItem("permissions").split(",");
    let PERMISSIONS_ARR = [];
    permissions.map((user_permission) => {
      Object.keys(PERMISSIONS).map(function (key) {
        if (PERMISSIONS[key] === user_permission) {
          PERMISSIONS_ARR.push(key);
        }
      });
    });
    return PERMISSIONS_ARR;
  }
};

export const isUserHasPermission = (permission) => {
  let USER_PERMISSIONS = ConvertPermissions();
  return USER_PERMISSIONS ? USER_PERMISSIONS.includes(permission) : null;
};

export const isSuperAdmin = () => {
  if (localStorage.getItem("token") && localStorage.getItem("roles")) {
    let rolesArr = localStorage.getItem("roles").split(",");
    let isSuperAdmin = rolesArr.includes("SUPER_ADMIN");
    return isSuperAdmin;
  }
};
