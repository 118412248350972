import * as jwt_decode from "jwt-decode";

import store from "../Store";
import { loader } from "../slices/MainSlice";
import messages from "../../assets/Local/Local";
import toast from "react-hot-toast";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
export const isLoaderShouldDisabled = (config = {}) => {
  return config.hasOwnProperty("disableLoader") ? true : false;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    if (!isLoaderShouldDisabled(request)) {
      document.body.classList.add("loading-indicator");
      store.dispatch(loader(true));
    }
    const token = localStorage.getItem("token");
    request.headers["Accept-Language"] =
      localStorage.getItem("lang") === "ar" ? "ar-SA" : "en-US";
    request.headers["Content-Type"] = "application/json";
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
};

export const successHandler = (response) => {
  if (!isLoaderShouldDisabled(response.config) && isHandlerEnabled(response)) {
    store.dispatch(loader(false));
    // toast.success(messages[localStorage.getItem("lang")].shared.done);
    document.body.classList.remove("loading-indicator");
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    document.body.classList.remove("loading-indicator");
    console.log(error.response.data.error);

    store.dispatch(loader(false));
    // if (error.response.status === 401) {
    //   localStorage.setItem(
    //     "prevPath",
    //     History.location.pathname + History.location.search
    //   );
    //   //   Auth.logout();
    //   History.push("/login");
    // }
    if (error.response.status === 409) {
      //   Auth.logout();
      History.push("/login");
    }
  }
  return Promise.reject({ ...error });
};

function getDecodedAccessToken(token) {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}
