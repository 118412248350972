import React from "react";
import MainLayout from "./../../Pages/MainLayout/MainLayout";
import { Navigate } from "react-router-dom";
import { isSuperAdmin, isUserHasPermission } from "../../Utils/Permissions";
import Navbar from "../Admin/Navbar/Navbar";

export const MainLayoutComponent = (component) => {
  return <MainLayout>{component}</MainLayout>;
};
export const NoLayout = (component) => {
  return <>{component}</>;
};
export default function ProtectedRoutes({
  component: Component,
  permission,
  path,
  ...rest
}) {
  const isAuthenticated = localStorage.getItem("isAuthenticated")
    ? true
    : false;
  // const isAuthenticated =true
  //   !isAuthenticated &&
  //     localStorage.setItem(
  //       "prevPath",
  //       window.location?.pathname + window.location?.search
  //     );

  const permissionArr =
    permission && typeof permission !== "boolean"
      ? permission.map((elt) => isUserHasPermission(elt))
      : [];

  return isAuthenticated ? (
    // permissionArr.some((elt) => elt === true) ||
    // isSuperAdmin() ||
    permission === true ? (
      // console.log(path)
      <MainLayout>
        <Navbar />
        <Component />
      </MainLayout>
    ) : (
      NoLayout(<Component />)
    )
  ) : (
    NoLayout(<Navigate to="/login" />)
  );

  //   ? NoLayout(<Component {...props} />)
  //   : NoLayout(<Navigate to={{ pathname: "/redirect-login" }} />)
}
