const en = {
  en: {
    buttons: {
      save: "Save",
      cancel: "Cancel",
      search: "Search",
      new: "New",
      langBtn: "AR",
    },
    shared: {
      login: "Sign in",
      password: "Password",
      email: "Email Address",
      done: "Done Successfully",
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected",
      from: "From",
      to: "To",
    },
    salaryGrades: {
      addNew: "Add New",
      manual: "Manual",
      aiSearching: "AI Searching",
      uploadFile: "Upload File",
      jobTitleNameEn: "Job Title (En)",
      jobTitleNameAr: "Job Title (Ar)",
      industry: "Industry",
      currency: "Currency",
      salaryMin: "Min",
      salaryMax: "Max",
      sector: "Sector",
      country: "Country",
      nationality: "Nationality",
      yearsOfExperience: "Years Of Experience",
      basicSalary: "Basic Salary",
      companyName: "Company Name",
      ai: "Ai",
      cell: "Cell",
      source: "Source",
      status: "Status",
      jobTitle: "Job Title",
      actions:'Actions'
    },
    errors: {
      required: "This field is required",
      numberRequired: "This field must contain only numbers",
      wizardFileSizeErr: "Max size is 3MB",
      wizardFileTypeErr: "Excel files are only allowed",
      validEmail: "Invalid email",
    },
    uploads: {
      agentsTooltipRepeatEmails: "Emails cannot be repeated",
      agentsTooltipMandatory: "All columns are mandatory",
      agentsTooltipSalaryNameMaxChar: "salary name is maxed 50 characters each",
      agentsTooltipMaxMinDiff: "Max should be greater than min",
      agentsTooltipSalaryNameUnique: "salary grade name should be unique",
      agentsTooltipDownload: "Download the Excel sheet sample first",
      agentsTooltipRemoveColumn:
        "Don't remove or add columns to the excel sheet",
      agentsTooltipFirstLastNameMaxChar:
        "First name, Last name are maxed 50 characters each",
      uploadExcel: "Import from excel",
      downloadSample: "Download sample",
    },
  },
};

export default en;
